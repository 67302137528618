import { HttpClient } from '@wix/http-client';
import {
  getSiteRolloutStatus,
  markEcomCompatible,
  markMigratedSiteEcomCompatible,
  canInstallEcomPages,
} from '@wix/ambassador-bookings-v1-site-rollout-status/http';

export class EditorScriptApi {
  constructor(private httpClient: HttpClient) {}

  async getIsBookingsOnEcom(): Promise<boolean> {
    try {
      const { data: siteRolloutStatusResponse } = await this.httpClient.request(
        getSiteRolloutStatus({}),
      );
      return siteRolloutStatusResponse.siteRolloutStatus.isBookingPlatformReady;
    } catch {
      return false;
    }
  }

  async migrateMediaGallery() {
    await this.httpClient.post(
      'https://editor.wix.com/internal/services-server/media-migration',
      { maxRedirects: 10 },
    );
  }

  async setEcomCompatible(): Promise<boolean> {
    try {
      const { data: siteRolloutStatusResponse } = await this.httpClient.request(
        markEcomCompatible({}),
      );

      return siteRolloutStatusResponse.siteRolloutStatus.isBookingPlatformReady;
    } catch {
      return false;
    }
  }

  async setMigratedSiteEcomCompatible(): Promise<boolean> {
    const { data: siteMigratedStatusResponse } = await this.httpClient.request(
      markMigratedSiteEcomCompatible({}),
    );
    return siteMigratedStatusResponse.siteRolloutStatus.isBookingPlatformReady;
  }

  async canInstallEcom(): Promise<boolean> {
    try {
      const {
        data: { canInstall },
      } = await this.httpClient.request(canInstallEcomPages({}));

      return canInstall;
    } catch {
      return false;
    }
  }
}
